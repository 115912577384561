%cf {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

%wrapper {
	margin-right: auto;
    margin-left: auto;
    width: $breakpoint-md;
    position: relative;

    @media only screen and (max-width: $breakpoint-md) {
        width: 100%;
        padding: 0 20px;
    }
}