@import '../scss/config/colours.scss';
@import '../scss/config/sizes.scss'; 
@import '../scss/config/fonts.scss'; 
@import '../scss/helpers/mixins.scss';

.Dropdown-control,
.Dropdown-menu {
	width: 40%;
	text-transform: uppercase;
	font-size: rem(18);
	border-radius: 4px;
	margin-bottom: 30px;

	@media only screen and (max-width: $breakpoint-sm) {
		width: 100%;
	}
}

.Dropdown-control {
	@include fontOpenSansCondensedBold;
	background: $brand-main;
	color: white;
	border: 0;
	padding: 0 0 0 15px;
}

.Dropdown-arrow {
	background-image: url('/assets/images/arrow.png');
	border: none;
	width: 26px;
	height: 26px;
}

.Dropdown-menu {
	border-color: $brand-main;
	right: 0;
}

.Dropdown-option {
	@include fontOpenSansCondensedLight;
	color: $brand-main;
	height: rem(45);
	line-height: rem(45);
	padding: 0 0 0 15px;

	&:hover,
	&.is-selected {
		background: $brand-secondary;
		color: $brand-main;
	}
}