@import url('https://fonts.googleapis.com/css?family=Hind:300,400,600,700|Open+Sans+Condensed:300,700');

$font-open-sans-condensed: 'Open Sans Condensed', helvetica, sans-serif;
$font-hind: 'Hind', helvetica, sans-serif;
$font-awesome: 'Font Awesome 5 Free';

@mixin fontOpenSansCondensedLight {
	font-family: $font-open-sans-condensed;
	font-weight: 300;
}

@mixin fontOpenSansCondensedBold {
	font-family: $font-open-sans-condensed;
	font-weight: 700;
}

@mixin fontHindLight {
	font-family: $font-hind;
	font-weight: 300;
}

@mixin fontHindRegular {
	font-family: $font-hind;
	font-weight: 400;
}

@mixin fontHindSemiBold {
	font-family: $font-hind;
	font-weight: 600;
}

@mixin fontHindBold {
	font-family: $font-hind;
	font-weight: 700;
}