@import '../scss/config/sizes.scss';
@import '../scss/config/colours.scss'; 

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader {
	z-index: 1;
	position: fixed;
	width: 100px;
	height: 100px;
	bottom: 0;
	right: 0;
  	background-color: $neg;
  	border: 2px solid $brand-main;
  	border-radius: 4px;

  	&:after {
  		content: '';
  		position: absolute;
  		left: calc(50% - 40px);
  		top: calc(50% - 40px);
  		width: 80px;
  		height: 80px;
  		animation: rotate360 2s linear infinite;
	 	background-image: url('/assets/images/loader.png');
	 	background-size: cover;
	 }

	&.hide-all {
		width: 100%;
		height: 100%;
		border: none;

		&:after {
			width: 160px;
			height: 160px;
			left: calc(50% - 80px);
  			top: calc(50% - 80px);
		}
	}
}