@import '../scss/config/colours.scss';
@import '../scss/config/fonts.scss';
@import '../scss/config/sizes.scss';
@import '../scss/helpers/mixins.scss';
@import '../scss/helpers/placeholders.scss';

.main {
	@extend %wrapper;
	position: relative;
	background-color: $neg;

	@media only screen and (max-width: $breakpoint-md) {
		padding: 0; // override standard wrapper behaviour
	}
}

.p {
	font-size: rem(20);
	text-align: center;
	margin-bottom: 25px;

	@media only screen and (max-width: $breakpoint-sm) {
		font-size: rem(17);
	}

	a {
		color: inherit;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}

	}
}

.h3 {
	color: $grey2;
}

.h4 {
	width: 50%;
	float: left;
	text-transform: uppercase;
	font-size: rem(17);
	margin: 10px 0;
}

.chooseLabels {
	font-size: rem(15);
}

.small {
	font-size: rem(14);
	margin-top: 15px;
}

.padding {
	padding: 40px 110px;

	@media only screen and (max-width: $breakpoint-md) {
		padding: 30px 30px;
	}

	@media only screen and (max-width: $breakpoint-xs) {
		padding: 30px 15px;
	}
}

.background {
	background: $brand-main;
	color: $neg;
}

.outline {
	border: 1px solid $grey3;
	padding: 15px 30px 0;
}

.border {
	border-bottom: 2px solid $brand-main;
}

.resultsBorder {
	display: none;
}

.subHead {
	color: $grey2;
	text-transform: uppercase;
	font-size: rem(20);
	margin-bottom: 20px;
}

.pink-underlined {
	color: $brand-main !important;
	text-decoration: underline !important;
}

:global(.print-view) {

	.termsBox {
		padding: 40px 0;
	}

	.pinkBox {
		overflow: hidden;
		margin-top: 30px;
		padding: 40px 110px 20px 110px;
	}

	.resultBased {
		padding: 40px 0;
	}

	.resultsBorder {
		height: 5px;
		display: block;
		border: none;
		background-color: $brand-main;
		margin-bottom: 40px;
	}

	.preparedHeader {
		font-weight: 400;
		color: $brand-main;
	}


}