@import '../scss/config/colours.scss';
@import '../scss/config/sizes.scss';
@import '../scss/helpers/mixins.scss';
@import '../scss/helpers/placeholders.scss';
@import '../scss/config/fonts.scss';

.input-wrapper {
	@extend %cf;
	padding-top: 35px;

	&--lg {
		line-height: rem($input-lg);
	}

	&--sm {
		line-height: rem($input-sm);
	}

	&--lg,
	&--sm {
		label {
			float: left;
			width: 60%;

			@media only screen and (max-width: $breakpoint-sm) {
				float: none;
				width: 100%;
				margin-bottom: 20px;
				line-height: rem(30);
			}
		}
	}

	@media only screen and (max-width: $breakpoint-sm) {
		margin-bottom: 20px;
		padding-top: 20px;
	}

	label {
		@media only screen and (max-width: $breakpoint-sm) {
			font-size: rem(23);
		}
	}

}

.input-wrapper+.input-wrapper {
	position: relative;
	border-top: 1px solid $brand-main;

	&::before {
		content: '';
		position: absolute;
		background: $brand-main;
		border-radius: 50%;
		width: 16px;
		height: 16px;
		right: 0px;
		top: -8px;
	}
}

.input {
	color: $grey2;
	background: none;
	float: right;
	border: 0;
	box-shadow: none;
	margin-top: -1px;
	width: 100%;
}

.wrapper {
	@include fontOpenSansCondensedLight;
	background: $grey5;
	color: $brand-main;
	font-size: rem(50);
	display: flex;
	padding-left: 15px;
	margin-bottom: 20px;
}