$brand-main: #f41c5e;
$brand-secondary: #fdebf1;

$neg: #fff;
$grey1: #515151;
$grey2: #838383;
$grey3: #999999;
$grey4: #dcdcdc;
$grey5: #f8f8f8;

$red: #C02235;
$darkblue: #34404C;
$lightblue: #507389;
