@import '../scss/config/colours.scss';
@import '../scss/config/sizes.scss';
@import '../scss/helpers/mixins.scss';
@import '../scss/config/fonts.scss';

.input {
	color: $grey2;
	background: none;
	float: right;
	border: 0;
	box-shadow: none;
	margin-top: -1px;
	width: 100%;
}

.wrapper {
	@include fontOpenSansCondensedLight;
	background: $grey5;
	color: $brand-main;
	font-size: rem(50);
	display: flex;
	padding-left: 15px;
	margin-bottom: 20px;
}