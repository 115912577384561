@import '../scss/config/colours.scss'; 
@import '../scss/config/sizes.scss'; 
@import '../scss/helpers/mixins.scss';

.intro {
    background-color: $brand-main;
    color: $neg;
    font-size: rem(25);
    text-align: center;
    padding: 40px 0 140px;
    margin-bottom: -100px;

    @media only screen and (max-width: $breakpoint-md) {
        padding-bottom: 40px;
        margin-bottom: 0;
    }

    @media only screen and (max-width: $breakpoint-sm) {
    	font-size: rem(18);
    	padding: 20px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
    	padding: 20px 10px;
    }
}