@import 'scss/generic/normalize.scss';
@import 'scss/generic/reset.scss';

@import 'scss/config/colours.scss';
@import 'scss/config/sizes.scss';
@import 'scss/config/fonts.scss';

@import 'scss/helpers/mixins.scss';
@import 'scss/helpers/utils.scss';

* {
	-webkit-font-smoothing: antialiased;
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	line-height: 1.15;
}

body {
	@include fontHindRegular;
	min-height: 100%;
	background: $grey4;
	overflow-y: scroll;
	overflow-x: hidden;
	font-size: rem(15);
	color: $grey3;
}

h3 {
	@include fontOpenSansCondensedBold;
	font-size: rem(25);
	text-transform: uppercase;
	margin-bottom: 25px;
	text-align: center;
}

b {
	@include fontHindBold;
}

a {
	font-weight: 600;
}

body.print-view {
	padding-top: 30px;
	background-color: white;
	width: 1200px;

	noscript {
		display: none;
	}

	.js-no-download {
		display: none;
	}

	.js-download {
		display: block;
	}

	.u-wrapper {
		width: 1000px;
	}

	#main {
		width: 1000px;
		margin: 0px auto;
	}

	#footer {
		box-shadow: none;
		background-color: white;
	}
}