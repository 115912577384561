@import '../scss/config/colours.scss'; 
@import '../scss/config/fonts.scss'; 
@import '../scss/helpers/mixins.scss'; 

button {
	@include fontHindSemiBold;
    font-weight: 600;
	border-radius: 4px;
	border: 1px solid $brand-main;
	padding: 13px 1.5em 9px;
	color: $brand-main;
	font-size: rem(17);
	background: none;
	min-width: 160px;

	&:focus {
		outline: none;
	}
	&:hover {
		background: $brand-secondary;
	}

	&.inverse {
		background: $neg;

		&:hover {
			background: $brand-secondary;
		}
	}

	&[disabled] {
		color: $grey4;
		border-color: $grey4;
		cursor: not-allowed !important;

		&:hover {
			background: $neg;
		}
	}
}