@import 'placeholders.scss'; 

.u-offscreen {
	position: absolute;
	top: -100%;
}

.u-inline {
    display: inline-block;
}

.u-float-right {
    float: right;
}

.u-float-left {
    float: left;
    margin-right: 20px;
}

.u-text-left {
    text-align: left;
}
.u-text-center {
    text-align: center;
}
.u-text-right {
    text-align: right;
}

.u-no-list {
    list-style: none;
}

.u-clearfix {
    @extend %cf;
}

.u-pointer {
    cursor: pointer;
}

.u-visible-sm {
    display: none;

    @media only screen and (max-width: $breakpoint-sm) {
        display: block;
    }
}

@media only screen and (max-width: $breakpoint-sm) {
    .u-hidden-sm {
        display: none !important;
    }
}

.u-hidden {
    display: none;
}

.u-wrapper {
    @extend %wrapper;
}

.u-text-highlight {
    white-space: nowrap;
    color: $brand-main;
}