@import '../scss/config/colours.scss';
@import '../scss/config/sizes.scss';
@import '../scss/config/fonts.scss';
@import '../scss/helpers/mixins.scss';


.box {
	width: 48%;
	width: calc(50% - 5px);
	float: left;
	background: $neg;
	color: $grey3;
	border-radius: 4px;
	text-align: center;
	padding: 15px 0 0;
	margin-bottom: 20px;

	@media only screen and (max-width: $breakpoint-sm) {
		width: 100%;
		float: none;
	}
}

.box+.box {
	margin-left: 10px;

	@media only screen and (max-width: $breakpoint-sm) {
		margin-left: 0;
	}
}

.h4 {
	font-size: rem(18);
	text-transform: uppercase;
	margin-bottom: 10px;

	&--highlighted {
		color: $brand-main;
		font-size: rem(28);
	}
}

.h5 {
	@include fontHindSemiBold;
	color: $brand-main;
	text-transform: uppercase;
	font-size: rem(14);
	margin-bottom: 5px;
}

.h6 {
	font-size: rem(30);
	margin-bottom: 5px;

	&--highlighted {
		color: $brand-main;
		font-size: rem(40);
	}
}

.hr {
	border: 2px solid $brand-main;
	margin-bottom: 15px;
}

.p {
	font-size: rem(10);
}

:global(.download-button) {
	clear: both;
	padding-top: 10px;
}

:global(.results-header) {
	margin-bottom: 40px;
}