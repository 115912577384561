@import '../scss/config/colours.scss';
@import '../scss/config/sizes.scss';
@import '../scss/config/fonts.scss';
@import '../scss/helpers/mixins.scss';

.header {
	padding: 25px 0 35px;
	text-align: center;
	position: relative;
	background: $neg;

	@media only screen and (max-width: $breakpoint-md) {
		padding: 10px 0 15px;
	}

	h1 {
		@include fontOpenSansCondensedLight;
		font-size: rem(32);
		text-transform: uppercase;
		color: $brand-main;
		padding: 20px 0 10px 70px;

		@media only screen and (max-width: $breakpoint-md) {
			font-size: rem(30);
			padding-top: 10px;
			padding-left: 0;
		}

		@media only screen and (max-width: $breakpoint-md) {
			font-size: rem(25);
		}
	}

	h2 {
		@include fontHindRegular;
	    font-size: rem(18);
	    color: $grey2;

		@media only screen and (max-width: $breakpoint-md) {
			font-size: rem(16);
		}
	}

	&__logo {
		position: absolute;
		left: 3px;
		top: 0;
		display: block;
		text-decoration: none;
		width: 180px;
		height: 90px;
		background-image: url('/assets/images/LifeInsurance_Pink.png');
		background-size: cover;
		text-indent: -1000px;
		overflow: hidden;


		@media only screen and (max-width: $breakpoint-md) {
			position: static;
			display: inline-block;
			margin-right: 10px;
		}

		@media only screen and (max-width: $breakpoint-sm) {
			margin-right: 0;
		}
	}

	&__sublogo {
		position: absolute;
		right: 0;
		top: 14px;

		@media only screen and (max-width: $breakpoint-md) {
			position: static;
			vertical-align: top;
		}

		@media only screen and (max-width: $breakpoint-md) {
			margin-left: 20px;
			margin-right: 20px;
		}
	}

	@media only screen and (max-width: $breakpoint-md) {
		&__logoLockup {
			padding: 15px 0 ;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.titleLine {
		@media only screen and (max-width: $breakpoint-md) {
			display: block;
		}
		@media only screen and (max-width: 570px) {
			font-size: 22px;
		}
	}


}