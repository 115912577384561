@import '../scss/config/colours.scss'; 
@import '../scss/config/sizes.scss'; 
@import '../scss/config/fonts.scss'; 
@import '../scss/helpers/mixins.scss'; 


.footer {
	background-color: $grey1;
	color: $grey3;
	box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
	padding: 20px 0;

	&__social-container {
		margin-bottom: 20px;
	}

	&__social-link {
		background-color: $grey3;
		border-radius: 50%;
		color: $grey1;
		display: inline-block;
		font-size: rem(24);
		margin-right: 10px;
		text-align: center;
		height: rem(44);
		width: rem(44);

		i {
			margin-top: 0.4em;
		}
	}
}