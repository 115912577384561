@import '../scss/config/colours.scss';
@import '../scss/helpers/mixins.scss';
@import '../scss/config/fonts.scss';
@import '../scss/config/sizes.scss';

.label {
	@include fontOpenSansCondensedBold;
	font-size: rem(25);
	color: $grey2;
	text-transform: uppercase;
	display: block;

	sup {
		font-size: rem(16);
	}

	@media only screen and (max-width: $breakpoint-sm) {
		text-align: center;
	}
}